export enum SubscriptionSource {
  BOOK_EMAIL_DEAL = 'book-email-deal',
  DEAL_ALERTS = 'deal-alerts',
  NEWSLETTER_SUBSCRIBE = 'newsletter-sign-up',
  NEWSLETTER_SUBSCRIBE_PAGE = 'newsletter-sign-up-page',
  PASSENGER_DETAILS = 'passenger-details-sign-up',
  PRICE_ALERTS = 'price-alerts',
  PRICE_ALERTS_OFFER_SUMMARY = 'price-alerts-offer-summary',
  PRICE_ALERTS_PANDA = 'price-alerts-panda',
  PRICE_TRACKER_PANDA = 'price-tracker-panda',
  SAVE_SEARCH = 'save-search',
  SHARE_DEAL = 'share-deal',
  PREFERENCE_CENTER = 'preference-center',
}

export enum SubscriptionName {
  MARKETING = 'marketing',
  NONE = 'NONE',
  SAVE_SEARCH = 'save-search',
  SHARE_DEAL = 'share-deal',
  MARKETING_PENDING = 'marketing-pending',
}
